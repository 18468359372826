@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.light-mode {
  background-color: #f3f4f6;
  color: #1f2937;
}

.dark-mode {
  background-color: #1f2937;
  color: #f3f4f6;
}

.dark-mode table {
  background-color: #374151;
}

.dark-mode th,
.dark-mode td {
  border-color: #4b5563;
}

.dark-mode select {
  background-color: #4b5563;
  color: #f3f4f6;
}

.dark-mode button {
  background-color: #6b7280;
  color: #f3f4f6;
}

.light-mode table {
  background-color: #ffffff;
}

.light-mode th,
.light-mode td {
  border-color: #e5e7eb;
}

.light-mode select {
  background-color: #ffffff;
  color: #1f2937;
}

.light-mode button {
  background-color: #e5e7eb;
  color: #1f2937;
}